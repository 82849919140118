import React from 'react'

import { Structure } from '../../Structure'
import { mergeClassList } from '../../../lib/utils'
import * as ProductIcons from 'components/ProductIcons'
import * as NotProductIcons from 'components/NotProductIcons'

interface TransparencyFeatureProps {
    image: string
    title: string
    children: any
    className?: string
}

interface TransparencyMattersProps {
    title: string
    description: string
    className?: string
}

const TransparencyMattersItem = ({ title, description, className = '' }: TransparencyMattersProps) => {
    const classList = mergeClassList('text-left', className)

    return (
        <div className={classList}>
            <div className="text-left">
                <h5 className="mb-2">{title}</h5>
                <p className="">{description}</p>
            </div>
        </div>
    )
}

const TransparencyFeature = ({ image, title, children, className = '' }: TransparencyFeatureProps) => {
    const classList = mergeClassList('flex flex-col w-full justify-between items-start', className)
    const Icon = NotProductIcons[image] || ProductIcons[image]
    return (
        <div className={classList}>
            {/* <div className="flex-shrink-0 flex justify-center items-start w-auto"> */}
            <div className="lex-shrink-0 flex h-16  w-16 justify-center">
                {Icon && <Icon className="mb-2 block h-24 w-24 max-w-full p-2 pl-0" />}
                {/* <img src={image} alt={title} className="max-w-full block h-24 w-24 p-2 pl-0 mb-2" /> */}
            </div>
            <div className="flex-grow text-left">
                <h3 className="my-2 text-xl">{title}</h3>

                {children}
            </div>
        </div>
    )
}

export const Transparency = (props) => {
    // console.log(`allTransparency  ${JSON.stringify(props)}`)
    const { allTransparency, googleImages } = props
    const { transparencyTitle, features, img } = allTransparency
    const googleImage =
        img &&
        googleImages[0] &&
        googleImages[0].filter((image) => {
            if (image.name == img) return image
        })[0]
    const googleImage2 =
        img &&
        googleImages[1] &&
        googleImages[1].filter((image) => {
            if (image.name == img) return image
        })[0]
    const gatsbyImage = googleImage && getImage(googleImage)
    const gatsbyImage2 = googleImage2 && getImage(googleImage2)
    return (
        <>
            <Structure.Section width="4xl">
                <Structure.SectionHeader
                    title={transparencyTitle?.title}
                    titleClassName="text-4xl"
                    titleTag="h2"
                    leadText={transparencyTitle?.subtitle}
                    leadTextClassName="opacity-80"
                />
            </Structure.Section>

            <div className="my-16 grid w-full border-l-0 border-r-0 border-t border-b border-dashed border-gray-accent-light text-left sm:grid-cols-2">
                {features?.length > 0 &&
                    features.map((feature) => {
                        return (
                            <div
                                key={feature.title}
                                className="border-b-0 border-t-0 border-r-0 border-dashed border-gray-accent-light px-4 py-8 sm:border-t-0 sm:border-l md:py-12"
                            >
                                <TransparencyFeature
                                    key={feature.title}
                                    title={feature.title}
                                    image={feature.image}
                                    className="mx-auto max-w-md"
                                >
                                    <p className="font-lightish mb-0  text-lg">{feature.description}</p>
                                </TransparencyFeature>
                            </div>
                        )
                    })}
            </div>
        </>
    )
}

export const WhoWeHire = (props) => {
    // console.log(`allWhoWeHire  ${JSON.stringify(props)}`)
    const { allWhoWeHire, googleImages } = props
    const { id, whoWeHireTitle, values, img } = allWhoWeHire
    const { title, subtitle, description, next } = whoWeHireTitle
    const googleImage =
        img &&
        googleImages[0] &&
        googleImages[0].filter((image) => {
            if (image.name == img) return image
        })[0]
    const googleImage2 =
        img &&
        googleImages[1] &&
        googleImages[1].filter((image) => {
            if (image.name == img) return image
        })[0]
    const gatsbyImage = googleImage && getImage(googleImage)
    const gatsbyImage2 = googleImage2 && getImage(googleImage2)
    return (
        <>
            <div id={id}>
                <Structure.Section width="5xl">
                    <Structure.SectionHeader title={title} titleTag="h2" leadText={subtitle} leadTextClassName="" />
                </Structure.Section>
            </div>
            <div className="px-4 pb-16 md:px-0">
                <p className="mx-auto mb-12 max-w-2xl text-base font-semibold">{description}</p>

                <h3 className="mb-4">{next}</h3>

                <div className="mx-auto grid max-w-6xl px-4 sm:grid-cols-3 sm:gap-8 lg:gap-24">
                    {values?.length > 0 &&
                        values.map((value) => <TransparencyMattersItem key={value.title} {...value} />)}
                </div>
            </div>
        </>
    )
}

import { RightArrow } from 'components/Icons/Icons'
import Link from 'components/Link'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

interface OpenRoleType {
    fields: {
        title: string
        slug: string
    }
    externalLink: string
    departmentName: string
}

interface DepartmentType {
    title: string
}

export default function IcloudreadyOpenRoles() {
    const { allJobPostings, allIcloudreadyJobPosting } = useStaticQuery(query)
    const departments = allJobPostings?.departments || allIcloudreadyJobPosting?.departments
    const jobs = allJobPostings?.jobs || allIcloudreadyJobPosting?.jobs
    // } ={"allIcloudreadyJobPosting":{"jobs":[{"fields":{"title":"senior marketing specialist","slug":"careers/senior-marketing-specialist"},"externalLink":"/marketing","departmentName":"marketing"}],"departments":[{"title":"marketing"}]}}
    // In order to show open roles, a valid Icloudready API key
    // must be added as an environment variable ICLOUDREADY_API_KEY.
    // If no Icloudready API key is found, this component shows nothing
    return (
        <ul className="m-0 list-none p-0">
            {departments?.length > 0 &&
                departments.map((department: DepartmentType) => {
                    const { title } = department
                    return (
                        <li key={title}>
                            <h3>{title}</h3>
                            <ul className="m-0 mt-4 mb-6 list-none border border-b-0 border-dashed border-gray-accent-light p-0">
                                {jobs?.length > 0 &&
                                    jobs
                                        .filter(
                                            (job: OpenRoleType) =>
                                                (job.frontmatter?.departmentName || job.departmentName) === title
                                        )
                                        .map((job: OpenRoleType) => {
                                            const title = job?.frontmatter?.title || job?.fields?.title
                                            const slug = job?.frontmatter?.slug || job?.fields?.slug
                                            return (
                                                <li
                                                    className="border-b border-dashed border-gray-accent-light"
                                                    key={title}
                                                >
                                                    <Link
                                                        className="flex justify-between px-4  py-3 text-[17px] font-bold hover:text-primary dark:hover:text-white"
                                                        to={slug}
                                                    >
                                                        <span>{title}</span>
                                                        <RightArrow className="bounce h-[24px] w-[24px] opacity-50 transition-opacity group-hover:opacity-100" />
                                                    </Link>
                                                </li>
                                            )
                                        })}
                            </ul>
                        </li>
                    )
                })}
        </ul>
    )
}

const query = graphql`
    query OpenRoles {
        allJobPostings: allMdx(
            filter: {
                frontmatter: { slug: { regex: "/careers/" }, isListed: { eq: true } }
                parent: { internal: { type: { eq: "GoogleDocs" } } }
            }
        ) {
            jobs: nodes {
                frontmatter {
                    slug
                    title
                    externalLink
                    departmentName
                    publishedDate: date
                    jobId: id
                    fields: customFields {
                        title
                        value
                    }
                }
            }
            departments: group(field: frontmatter___departmentName) {
                title: fieldValue
            }
        }
        allIcloudreadyJobPosting(
            filter: { isListed: { eq: true }, parent: { internal: { type: { ne: "GoogleDocs" } } } }
        ) {
            jobs: nodes {
                fields {
                    title
                    slug
                }
                externalLink
                departmentName
            }
            departments: group(field: departmentName) {
                title: fieldValue
            }
        }
    }
`

import React from 'react'
import { Structure } from '../../Structure'
import { AnchorScrollNavbarTop } from './AnchorScrollNavbarTop'
import { GatsbyImage, getImage, withArtDirection, getSrc, StaticImage } from 'gatsby-plugin-image'

const P = ({ children }) => {
    return <p className="text-justify text-[17px] leading-relaxed">{children}</p>
}

export const WhyWereHere = (props) => {
    // console.log(`allWhyWereHere  ${JSON.stringify(props)}`)
    const { allWhyWereHere, googleImages } = props
    const { letter, cta, image } = allWhyWereHere
    const { title, subtitle, description } = letter
    const googleImage =
        image &&
        googleImages[0] &&
        googleImages[0].filter((img) => {
            if (img.name == image) return img
        })[0]
    const googleImage2 =
        image &&
        googleImages[1] &&
        googleImages[1].filter((img) => {
            if (img.name == image) return img
        })[0]
    const gatsbyImage = googleImage && getImage(googleImage)
    const gatsbyImage2 = googleImage2 && getImage(googleImage2)
    return (
        <div id="introduction" className="mb-16">
            <div className="text-left">
                <Structure.Section width="5xl" className="text-lg">
                    <div className="grid-cols-4 gap-8 lg:grid">
                        <div className="text-gray-100 relative col-span-3 rounded bg-white/90 px-12 pt-6 pb-12 text-opacity-80 shadow-xl backdrop-blur dark:bg-dark md:px-20">
                            <div className=" before:absolute before:left-0 before:top-0 before:h-full before:w-[6px]">
                                <div className="space-between mb-8 flex w-full items-center border-b border-dashed border-gray-accent-light pb-4">
                                    <div className="flex-1">
                                        <p className="mb-0 text-[17px] font-semibold leading-tight opacity-50">
                                            {title}
                                        </p>
                                    </div>
                                    <figure className="mb-0">
                                        <GatsbyImage
                                            image={gatsbyImage}
                                            alt="Ahmed Elazab"
                                            className="h-14 w-14 rounded-full border-2 border-solid border-white bg-yellow"
                                            placeholder="blurred"
                                        />
                                    </figure>
                                </div>
                                <div>
                                    <h3>{subtitle}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                    {/* <div className="flex items-center space-x-3">
                                        <StaticImage
                                            src="../../../images/signature-initials-ahmed.png"
                                            alt="-"
                                            width={48.5}
                                            height={57}
                                            quality={100}
                                            placeholder="none"
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="mt-48 hidden px-5 lg:block lg:px-8 lg:pb-4">
                            <h4 className=" m-0 mb-1 text-sm font-semibold opacity-25 dark:text-white">{cta.name}</h4>
                            <ul className="m-0 flex list-none flex-col p-0">
                                <li className="active-product relative m-0 leading-none">
                                    <span className="block">
                                        <a className="relative block cursor-pointer py-1 text-[14px] font-medium leading-tight text-almost-black opacity-60 hover:text-red hover:opacity-100 active:top-[0.5px] active:scale-[.99] dark:text-white dark:hover:text-red">
                                            {cta.url}
                                        </a>
                                    </span>
                                </li>
                                <AnchorScrollNavbarTop />
                            </ul>
                        </div>
                    </div>
                </Structure.Section>
            </div>
        </div>
    )
}

import IcloudreadyOpenRoles from 'components/IcloudreadyOpenRoles'
import React from 'react'
import { Structure } from '../../Structure'

export const OpenRoles = (props) => {
    //  console.log(`allOpenRoles  ${JSON.stringify(props)}`)
    const { id, title, description, googleImages } = props
    return (
        <div id={id} className="mt-16">
            <Structure.Section width="5xl" className="">
                <h2 className="mb-12 text-center text-5xl">{title}</h2>

                <div className="md:flex">
                    <div className="mx-auto mb-12 max-w-md flex-1 md:mr-16 md:mt-4 md:mb-0">
                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    </div>
                    <div className="mx-auto max-w-md flex-1">
                        <IcloudreadyOpenRoles />
                    </div>
                </div>
            </Structure.Section>
        </div>
    )
}

import Layout from 'components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import AnchorScrollNavbar from 'components/AnchorScrollNavbar'
import { Benefits } from 'components/Careers/Benefits'
import { CareersHero } from 'components/Careers/CareersHero'
import { InterviewProcess } from 'components/Careers/InterviewProcess'
import { OpenRoles } from 'components/Careers/OpenRoles'
import 'components/Careers/styles/index.scss'
import { Transparency, WhoWeHire } from 'components/Careers/Transparency'
import { WhyWereHere } from 'components/Careers/WhyWereHere'
import { WorkingAtIcloudReady } from 'components/Careers/WorkingAtIcloudReady'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import { TeamQuote } from 'components/TeamQuote'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Link from 'components/Link'
import PostLayout from 'components/PostLayout'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { shortcodes } from '../../mdxGlobalComponents'

export const SectionWrapper = ({ children, className = '' }: { children: React.ReactNode }) => {
    return <ul className={`${className} m-0 grid list-none p-0`}>{children}</ul>
}

export const ColumnsWrapper = ({ children, className = '' }: { children: React.ReactNode }) => {
    return <div className={`${className}`}>{children}</div>
}

// export const Head = ({
//     location,
//     params,
//     data: { allJobPostings, allIcloudreadyJobPosting },
//     pageContext: { documentationNav },
// }) => {
//     const latestJob =
//         (allJobPostings?.nodes && allJobPostings?.nodes[0]) ||
//         (allIcloudreadyJobPosting?.nodes && allIcloudreadyJobPosting.nodes[0])
//     const latestJobCreatedAt = latestJob && new Date(latestJob['publishedDate'])
//     return (
//         <SEO2
//             title={'Careers'}
//             description={`Join the Journey: Real Estate SaaS Tech Company Seeking Adventurers! Learn more about iCloudReady career opportunities in San Francisco California, Cairo Egypt, and more.`}
//             image={`/og-images/careers.jpeg${latestJobCreatedAt ? `?${latestJobCreatedAt.getTime()}` : ''}`}
//         />
//     )
// }

export default function Careers(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { allJobPostings, allIcloudreadyJobPosting, documentation, tutorials, customers } = data
    const firstJobPost = allJobPostings?.nodes?.[0]
    const body = firstJobPost?.body
    const excerpt = firstJobPost?.excerpt
    const frontmatter = firstJobPost?.frontmatter
    const parent = firstJobPost?.parent
    const slug = firstJobPost?.fields?.slug
    const logoFileName = frontmatter?.logoFileName
    const title = frontmatter?.title
    const subtitle = frontmatter?.subtitle
    const description = frontmatter?.description
    const sidebar = frontmatter?.sidebar
    const pageStyle = frontmatter?.pageStyle
    const darkMode = frontmatter?.darkMode
    const hideSurvey = frontmatter?.hideSurvey
    const OtherOptionsSections = frontmatter?.OtherOptionsSections
    const faqs = frontmatter?.faqs
    const exclude = frontmatter?.exclude

    const latestJob =
        (allJobPostings?.nodes && allJobPostings?.nodes[0]) ||
        (allIcloudreadyJobPosting?.nodes && allIcloudreadyJobPosting.nodes[0])
    const latestJobCreatedAt = latestJob && new Date(latestJob['publishedDate'])
    const breakpoints = useBreakpoint()
    const components = {
        ...shortcodes(pageProps),
        CareersHero: (props: any) => <CareersHero {...props} googleImages={[parent?.images, parent?.images2]} />,
        WhyWereHere: (props: any) => <WhyWereHere {...props} googleImages={[parent?.images, parent?.images2]} />,
        CareersAnchorScrollNavbar: (props: any) => (
            <div id="careersAnchorScrollNavbar" className="careers-nav sticky top-0 z-10 bg-tan py-2">
                <AnchorScrollNavbar
                    autoScroll={breakpoints.md}
                    {...props}
                    googleImages={[parent?.images, parent?.images2]}
                />
            </div>
        ),
        Transparency: (props: any) => <Transparency {...props} googleImages={[parent?.images, parent?.images2]} />,
        WhoWeHire: (props: any) => <WhoWeHire {...props} googleImages={[parent?.images, parent?.images2]} />,
        TeamQuote: (props: any) => <TeamQuote {...props} googleImages={[parent?.images, parent?.images2]} />,
        InterviewProcess: (props: any) => (
            <InterviewProcess {...props} googleImages={[parent?.images, parent?.images2]} />
        ),
        Benefits: (props: any) => <Benefits {...props} googleImages={[parent?.images, parent?.images2]} />,
        WorkingAtIcloudReady: (props: any) => (
            <WorkingAtIcloudReady {...props} googleImages={[parent?.images, parent?.images2]} />
        ),
        OpenRoles: (props: any) => <OpenRoles {...props} googleImages={[parent?.images, parent?.images2]} />,
    }
    // if ((!firstJobPost || exclude) && !process.env.RENDER_DISABLED_COMPONENTS) {
    //     return <></>
    // }
    return (
        <Layout>
            <SEO
                title={'Careers'}
                description={`Join the Journey: Real Estate SaaS Tech Company Seeking Adventurers! Learn more about iCloudReady career opportunities in San Francisco California, Cairo Egypt, and more`}
                image={`/og-images/careers.jpeg${latestJobCreatedAt ? `?${latestJobCreatedAt.getTime()}` : ''}`}
            />
            <MDXProvider components={components}>
                <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
        </Layout>
    )
}

import Careers from 'components/Careers'
import { graphql } from 'gatsby'

export default Careers

export const query = graphql`
    query CareersQuery {
        allJobPostings: allMdx(
            filter: { frontmatter: { slug: { eq: "/careers" } }, parent: { internal: { type: { eq: "GoogleDocs" } } } }
        ) {
            nodes {
                body
                id
                internal {
                    contentFilePath
                }
                headings {
                    depth
                    value
                }
                fields {
                    slug
                }
                frontmatter {
                    subtitle
                    logoFileName
                    description
                    exclude
                    sidebar
                    pageStyle
                    darkMode
                    hideSurvey
                    slug
                    title
                    publishedDate: date
                    jobId: id
                    fields: customFields {
                        title
                        value
                    }
                }
                parent {
                    ... on GoogleDocs {
                        id
                        teams {
                            name
                            roadmaps {
                                idengagerId
                                betaAvailable
                                complete
                                dateCompleted
                                title
                                description
                                projectedCompletion
                                img
                                githubPages {
                                    title
                                    html_url
                                    number
                                    closed_at
                                    reactions {
                                        hooray
                                        heart
                                        eyes
                                        plus1
                                    }
                                }
                            }
                        }
                        images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        images2: images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 100, height: 100)
                            }
                        }
                    }
                }
            }
        }
        allIcloudreadyJobPosting(
            filter: { parent: { internal: { type: { ne: "GoogleDocs" } } } }
            sort: { fields: publishedDate, order: DESC }
        ) {
            nodes {
                publishedDate
                title
            }
        }
    }
`

import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection, getSrc, StaticImage } from 'gatsby-plugin-image'

import { Header } from '../../Header'
import { CallToAction } from '../../CallToAction'

export const CareersHero = (props) => {
    const { jobs, allJobPostings } = useStaticQuery(query)
    //    console.log(`allCareersHero  ${JSON.stringify(props)}`)
    const { allCareersHero, googleImages } = props
    const { id, title, subtitle, next, image } = allCareersHero
    const googleImage =
        img &&
        googleImages[0] &&
        googleImages[0].filter((image) => {
            if (image.name == img) return image
        })[0]
    const googleImage2 =
        img &&
        googleImages[1] &&
        googleImages[1].filter((image) => {
            if (image.name == img) return image
        })[0]
    const gatsbyImage = googleImage && getImage(googleImage)
    const gatsbyImage2 = googleImage2 && getImage(googleImage2)
    return (
        <div className="careers-hero md:pb-18 pb-12 lg:pt-12">
            <div className="relative rounded">
                <div className="-top-8 right-0 max-w-5xl md:absolute md:w-1/2 lg:w-[45%] xl:-top-6 2xl:-top-8 2xl:w-[45%]">
                    <GatsbyImage
                        image={gatsbyImage}
                        alt="Construction readys taking a lunch break"
                        className="h-full w-full"
                        placeholder="blurred"
                    />
                </div>

                <div className="relative z-10 mx-auto max-w-7xl px-4 text-center md:pr-0 md:text-left lg:pl-8">
                    <h1 className="xl:[w-2/3] mb-0 pb-0 text-3xl md:w-2/3 md:text-4xl lg:w-[55%] lg:text-5xl 2xl:text-6xl">
                        {title}
                        <span className="text-red">{subtitle}</span>
                    </h1>

                    <CallToAction
                        onClick={() => scrollTo('#open-roles')}
                        type="primary"
                        width="72"
                        className="my-6 sm:my-12"
                    >
                        {next} ({allJobPostings?.totalCount || jobs.totalCount})
                    </CallToAction>
                </div>
            </div>
        </div>
    )
}

const query = graphql`
    {
        allJobPostings: allMdx(
            filter: {
                frontmatter: { slug: { regex: "/careers/" }, isListed: { eq: true } }
                parent: { internal: { type: { eq: "GoogleDocs" } } }
            }
        ) {
            totalCount
        }
        jobs: allIcloudreadyJobPosting(filter: { isListed: { eq: true } }) {
            totalCount
        }
    }
`

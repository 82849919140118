import React, { useState, useRef } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Structure } from '../../Structure'
import Slider from 'react-slick'
import application from './images/application.svg'
import culture from './images/culture.svg'
import technical from './images/technical.svg'
import superday from './images/superday.svg'
import offer from './images/offer.svg'
import SliderNav from '../../SliderNav'
import * as ProductIcons from 'components/ProductIcons'
import * as NotProductIcons from 'components/NotProductIcons'

import './index.css'

const SliderItem = ({ image, title, subtitle, description }) => {
    const Icon = NotProductIcons[image] || ProductIcons[image]
    return (
        <div className="flex flex-col items-start border-r border-dashed border-gray-accent-light py-5 px-10 sm:max-w-[calc(100vw/2+50px)] md:max-w-[calc(100vw/3+50px)] xl:max-w-[calc(100vw/4+50px)] 2xl:max-w-[calc(100vw/5+50px)]">
            {/* <img className="mb-9" src={image} /> */}
            {Icon && <Icon className={`mb-9`} />}
            <h4 className="m-0 text-primary">{title}</h4>
            <h5 className="mb-3 font-semibold leading-tight text-primary">{subtitle}</h5>
            <p className="m-0 text-[15px] text-primary">{description}</p>
        </div>
    )
}

export const InterviewProcess = (props) => {
    // console.log(`allInterviewProcess  ${JSON.stringify(props)}`)
    const { allInterviewProcess, googleImages } = props
    const { interviewProcessTitle, steps } = allInterviewProcess
    const { id, title, subtitle, description } = interviewProcessTitle
    const sliderRef = useRef(null)
    const [currentSlide, setCurrentSlide] = useState(0)
    const sliderSettings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToScroll: 1,
        autoplay: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 639,
                settings: {
                    variableWidth: false,
                },
            },
        ],
    }
    const breakpoints = useBreakpoint()
    const slidesToShow = breakpoints.md ? 1 : breakpoints.lg ? 2 : breakpoints.xl ? 3 : 4
    const handleChange = (_oldIndex, newIndex) => {
        setCurrentSlide(newIndex)
    }
    return (
        <div className="mb-16 pt-24 text-center" id="interview-process">
            <Structure.Section width="5xl">
                <Structure.SectionHeader title={title} titleTag="h2" leadText={subtitle} leadTextClassName="" />
            </Structure.Section>
            <SliderNav
                handlePrevious={() => sliderRef.current.slickPrev()}
                handleNext={() => sliderRef.current.slickNext()}
                currentIndex={currentSlide}
                length={5 - slidesToShow}
            />
            <Slider
                beforeChange={handleChange}
                className="careers-slider text-left"
                ref={sliderRef}
                slidesToShow={slidesToShow}
                {...sliderSettings}
            >
                {steps?.length > 0 && steps.map((step) => <SliderItem key={step.title} {...step} />)}
            </Slider>
        </div>
    )
}

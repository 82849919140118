import React from 'react'
import { Structure } from '../../Structure'
import { mergeClassList } from '../../../lib/utils'

export const WorkingAtIcloudReady = (props) => {
    // console.log(`allWorkingAtIcloudReady  ${JSON.stringify(props)}`)
    const { id, title, description, googleImages } = props
    return (
        <div id={id}>
            <Structure.Section
                width="4xl"
                className="items-center border-l-0 border-r-0 border-t border-b border-dashed border-gray-accent-light py-12 md:flex"
            >
                <div className="flex-0 mb-12 text-center md:mb-0 md:mr-4 md:max-w-xs md:text-left">
                    <h3 className="text-4xl" dangerouslySetInnerHTML={{ __html: description }}></h3>
                </div>
                {/* <div className="flex-1">
                    <div className="h-0 pb-fluid-video relative">
                        <iframe
                            src="https://www.youtube.com/embed/111"
                            className="absolute top-0 left-0 w-full h-full rounded-lg"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                            frameBorder="0"
                        ></iframe>
                    </div>
                </div> */}
            </Structure.Section>
        </div>
    )
}

import React from 'react'
import { GatsbyImage, getImage, withArtDirection, getSrc, StaticImage } from 'gatsby-plugin-image'
interface CallToActionProps {
    backgroundColor?: string
    darkBackgroundColor?: string
    textColor?: string
    fontSize?: string
    width: string
    value: string
    author: string
    role: string
    img: string
}

export const TeamQuote = (props) => {
    // console.log(`allTeamQoute  ${JSON.stringify(props)}`)
    const { allTeamQuote, googleImages } = props
    // if (Array.isArray(allTeamQoute) && allTeamQoute.length == 1) allTeamQoute = allTeamQoute[0]
    const { backgroundColor, textColor, fontSize, width, value, author, role, img } = allTeamQuote
    const darkBackgroundColor = allTeamQuote?.darkBackgroundColor || 'bg-blue'
    const googleImage =
        img &&
        googleImages[0] &&
        googleImages[0].filter((image) => {
            if (image.name == img) return image
        })[0]
    const googleImage2 =
        img &&
        googleImages[1] &&
        googleImages[1].filter((image) => {
            if (image.name == img) return image
        })[0]
    const gatsbyImage = googleImage && getImage(googleImage)
    const gatsbyImage2 = googleImage2 && getImage(googleImage2)
    // const images = withArtDirection(gatsbyImage, [
    //   {
    //     media: "(max-width: 600px)",
    //     image: gatsbyImage2,
    //   },
    // ]);
    return (
        <div className={`${backgroundColor} dark:${darkBackgroundColor} `}>
            <blockquote
                className={`${backgroundColor} dark:${darkBackgroundColor} ${width} mx-auto px-8 py-16 xl:px-0`}
            >
                <h4 className={`${textColor} ${fontSize} leading-snug`}> {value}</h4>
                <footer className="mt-4 flex">
                    <div className="flex-0 mr-4">
                        <GatsbyImage image={gatsbyImage2} imgClassName="rounded-full " alt={`${author} - ${role}`} />
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <h4 className={`${textColor} mb-0 leading-tight`}>{author}</h4>
                        <h4 className={`${textColor} mb-0 leading-tight text-opacity-50`}>{role}</h4>
                    </div>
                </footer>
            </blockquote>
        </div>
    )
}
